import { Component, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { reportHeadersFromKey } from '@/support/ReportHeaders';
import { cloneDeep } from 'lodash';
import ReportTypeFilter from '@/components/filters/report-type-filter/ReportTypeFilter.vue';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { DossierUitTermijnInfo } from '@/support/Info';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { SortOrder } from '@/models/Model';

@Component<DossiersUitTermijn>({
  components: {
    ReportTypeFilter,
  },
})
export default class DossiersUitTermijn extends AbstractMoreInfoDialog {
  public $pageTitle = 'Dossier uit termijn';

  // loaders
  protected isLoading = true;

  protected filters: DossiersUitTermijnFilters = {
    almost_kpi: 'overdue',
  };

  protected tableFilters: DossiersUitTermijnFilters = {};

  protected savedFilters: {[id: string]: DossiersUitTermijnFilters} = {};

  protected moreInfo: MoreInfo[] = DossierUitTermijnInfo;

  protected organizations: Organization[] | null = null;

  protected activeOrganization = '';

  // Datatable
  protected visibility: TableVisibility = {
    checkboxes: false,
    title: false,
    search: false,
  };

  protected types = [
    {
      name: 'Regulier',
      value: 'regulier',
    },
    {
      name: 'Nader advies',
      value: 'zienswijze',
    },
    {
      name: 'Bezwaar',
      value: 'bezwaar',
    },
    {
      name: 'SMR',
      value: 'smr',
    },
  ];

  protected classificaties = [
    {
      name: 'Termijn verstreken',
      value: 'overdue',
    },
    {
      name: 'Uit 26 weken termijn ',
      value: 'twenty_six_weeks_overdue',
    },
    {
      name: 'Risico dossiers doorlooptijd',
      value: 'almost_overdue',
    },
  ];

  // Dates
  protected databaseDate = '0000-00-00 00:00:00';

  public async mounted() {
    this.isLoading = true;

    this.setUrlFilters();
    await this.initialize();
    this.isLoading = false;
  }

  protected async initialize() {
    this.emitBreadcrumb();
    await this.getOrganizations();

    this.$nextTick(() => {
      this.applyFilters();
    });
  }

  protected setUrlFilters() {
    const filters: {[key: string]: string} = {};
    Object.keys(this.$route.query).forEach((key) => {
      if (key === 'sort' || key === 'sort_order') { return; }
      filters[key] = `${this.$route.query[key]}`;
    });

    this.filters = { ...this.filters, ...filters };
  }

  protected async getOrganizations() {
    if (this.$store.state.isServiceOrganization) {
      this.organizations = await new Organization()
        .getAllExperts();

      if (this.organizations && ! this.activeOrganization) {
        this.activeOrganization = this.organizations[0].id || '';
        this.organizations.forEach((organization) => {
          if (organization?.id) {
            this.savedFilters[organization.id] = cloneDeep(this.filters);
          }
        });
      }
    } else {
      this.activeOrganization = this.$store.state.Auth.organization.id;
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.savedFilters[this.activeOrganization] = cloneDeep(this.filters);
    }
  }

  protected applyFilters() {
    const filters = {
      ohw: 'todo',
      overdue_kpi: this.filters.almost_kpi === 'overdue',
      almost_overdue_kpi: this.filters.almost_kpi === 'almost_overdue',
      twenty_six_weeks_overdue_kpi: this.filters.almost_kpi === 'twenty_six_weeks_overdue',
    };
    this.tableFilters = { ...this.filters, ...filters };
  }

  // Getters
  protected get dataTableFilters() {
    return this.tableFilters;
  }

  protected get tableOptions() {
    return {
      model: new Report()
        .dmz(this.activeOrganization)
        .sort(this.sort, this.sortOrder)
        .filter(this.dataTableFilters),
      headers: reportHeadersFromKey('kpi_overdue'),
      actions: [
        {
          type: 'edit',
          label: 'view',
          icon: 'remove_red_eye',
          tooltip: 'Bekijk Dossier',
          action: '/reports/{id}',
        },
      ],
      filter: [],
    };
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Dossiers uit termijn' },
        ],
      });
  }

  protected refreshDataTable() {
    const datatable = this.$refs.ftrDatatable as DataTable;
    if (! datatable) { return; }

    datatable.refresh();
  }

  protected activeOrganizationChanged(id: string) {
    this.filters = this.savedFilters[id];
    this.applyFilters();
  }

  protected get isSupervisorView(): boolean {
    return ! this.$store.state.Auth.has_management_info && this.$store.state.Auth.supervisor_info && this.$store.state.Auth.supervisor_info.is_supervisor;
  }

  protected get sort() {
    return this.$route.query?.sort ? `${this.$route.query.sort}` : 'planned_at';
  }

  protected get sortOrder(): SortOrder {
    if (this.$route.query?.sort_order === 'ASC' || this.$route.query?.sort_order === 'DESC') {
      return `${this.$route.query.sort_order}` as SortOrder;
    }

    return 'ASC';
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
    this.initialize();
  }

  @Watch('filters', { deep: true })
  protected filtersChanged() {
    this.savedFilters[this.activeOrganization] = this.filters;
  }
}

interface DossiersUitTermijnFilters {
  organization?: string;
  downloaded_at?: any;
  ftr?: string;
  almost_kpi?: string;
  ftr_confirmed?: boolean;
  ftr_statuses?: string[];
  experts?: string[];
  kpiftr?: boolean;
  WorkFlows?: string;
  opname_variants?: string;
  management_info_classes?: string;
}
