var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Dossiers uit termijn")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              !_vm.organizations
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("LoaderCard", {
                            attrs: { flat: "", type: "spinner--center" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeOrganization,
                    callback: function($$v) {
                      _vm.activeOrganization = $$v
                    },
                    expression: "activeOrganization"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: {
                            active: _vm.activeOrganization === organization.id
                          },
                          attrs: {
                            href: "#" + organization.id,
                            disabled: _vm.isLoading
                          },
                          on: {
                            click: function($event) {
                              return _vm.activeOrganizationChanged(
                                organization.id
                              )
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeOrganization,
                        callback: function($$v) {
                          _vm.activeOrganization = $$v
                        },
                        expression: "activeOrganization"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "termijn-tab-content-" + organization.id,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--spaced fill-height"
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "justify-end": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "justify-space-between": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "info-icon",
                                                  attrs: { shrink: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.openReadMoreDialog(
                                                                            "termijn"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info_outline"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "klik voor meer info"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-end": "" } },
                                    [
                                      !_vm.isSupervisorView
                                        ? _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm3: "",
                                                "mb-2": ""
                                              }
                                            },
                                            [
                                              _c("ReportDepartmentFilter", {
                                                attrs: {
                                                  label: "Afdelingen",
                                                  placeholder: "",
                                                  cssClass: "with-border",
                                                  dmz: _vm.activeOrganization,
                                                  canSelectAll: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.filters.departments,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filters,
                                                      "departments",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filters.departments"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("ReportTypeFilter", {
                                            attrs: {
                                              label: "Rapporttype",
                                              placeholder: "",
                                              cssClass: "with-border",
                                              canSelectAll: true
                                            },
                                            model: {
                                              value: _vm.filters.types,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "types",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.types"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.classificaties,
                                              "item-value": "value",
                                              "item-text": "name",
                                              label: "Classificatie",
                                              "hide-details": ""
                                            },
                                            model: {
                                              value: _vm.filters.almost_kpi,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "almost_kpi",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.almost_kpi"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("ReportTagFilter", {
                                            attrs: {
                                              label: "Tags",
                                              placeholder: "",
                                              cssClass: "with-border",
                                              canSelectAll: true
                                            },
                                            model: {
                                              value: _vm.filters.tags,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "tags",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.tags"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("WorkFlowFilter", {
                                            attrs: {
                                              label: "Workflow",
                                              placeholder: "",
                                              cssClass: "with-border",
                                              canSelectAll: true
                                            },
                                            model: {
                                              value: _vm.filters.workflows,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "workflows",
                                                  $$v
                                                )
                                              },
                                              expression: "filters.workflows"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("OpnameVariantFilter", {
                                            attrs: {
                                              label: "Opname variant",
                                              placeholder: "",
                                              cssClass: "with-border",
                                              canSelectAll: true
                                            },
                                            model: {
                                              value:
                                                _vm.filters.opname_variants,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "opname_variants",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filters.opname_variants"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm3: "",
                                            "mb-2": ""
                                          }
                                        },
                                        [
                                          _c("ManagementClassFilter", {
                                            attrs: {
                                              label: "Rapportage classificatie",
                                              placeholder: "",
                                              cssClass: "with-border",
                                              canSelectAll: true
                                            },
                                            model: {
                                              value:
                                                _vm.filters
                                                  .management_info_classes,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  "management_info_classes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filters.management_info_classes"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "text-xs-right",
                                          attrs: { sm12: "" }
                                        },
                                        [
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "true",
                                                outline: "true"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.applyFilters()
                                                }
                                              }
                                            },
                                            [_vm._v(" Pas filters toe ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c("v-flex", {
                                        staticClass: "separator",
                                        attrs: { xs12: "" }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.isLoading
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("LoaderCard", {
                                                attrs: {
                                                  flat: "",
                                                  type: "spinner--center"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isLoading
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("DataTable", {
                                                ref: "ftrDatatable",
                                                refInFor: true,
                                                attrs: {
                                                  options: _vm.tableOptions,
                                                  visibility: _vm.visibility
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }